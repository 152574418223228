






















































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { namespace } from 'vuex-class'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'

import ServicesByRegionalField from '@/partials/forms/components/ServicesByRegional.vue'
import NotificationMixin from '@/mixins/notificationMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    TrashCan16,
    ArrowUp16,
    ArrowDown16,
    ServicesByRegionalField,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class ServiceSurveyCreate extends Mixins(NotificationMixin, FlashMessageMixin, ValidatorConfigMixin) {
  @sessionModule.Getter state!: sessionState

  form: Record<string, any> = {
    survey: '',
    senderId: '',
    subject: '',
    services: [],
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  options = [
    {
      option: '',
      key: 1
    },
    {
      option: '',
      key: 2
    },
    {
      option: '',
      key: 3
    },
    {
      option: '',
      key: 4
    }
  ]

  optionsType = 'radio'
  optionsOthers = false
  optionsOthersText = ''

  globalKey = 5

  removeChoice (index: number) {
    if (this.options.length > 2) {
      this.options.splice(index, 1)
    }
  }

  addChoice (index: number) {
    const choice = { option: '', key: this.globalKey++ }
    this.options.splice(index, 0, choice)
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.options[targetIndex]

    this.options.splice(targetIndex, 1)
    this.options.splice(index, 0, swappingChoice)
  }

  regionals = []

  done = true

  submit () {
    this.done = false

    const submitForm: any = { ...snakeCaseKeys(this.form), options: [] }
    submitForm.services = submitForm.services.flat()
    submitForm.options = this.options.map((option, index) => {
      return { option: option.option, order: index, type: this.optionsType }
    })

    if (this.optionsOthers) {
      submitForm.options.push({ option: this.optionsOthersText ? this.optionsOthersText : 'Outros', order: 999, type: 'text' })
    }

    axios.post('/service/survey', submitForm)
      .then(response => {
        const flashMessage = {
          message: response.data.data,
          isSuccess: true
        }
        this.$router.push({ name: 'ServiceSurveys' }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  created () {
    this.form.senderId = this.state.user.id

    axios.get('/services/groupby/regional')
      .then(response => {
        this.regionals = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
